<template>
  <div>
    <b-modal id="modal-request-sub" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc>
      <img src="/icon/layout/close-modal-question.svg" alt="Close" class="pointer close-modal" @click="showModal = false"/>
      <img class="logo" :src="require('@/assets/mlearn/icons/menu/vilearn.svg')" alt="logo"/>
      <div class="title text-center">
        <p> Đăng ký gói ngay để nhận <span>10</span> câu hỏi/ngày.</p>
      </div>
      <div class="action d-flex justify-content-end">
        <button class="detail" @click="showMessage">Đăng ký ngay</button>
      </div>
    </b-modal>
    <b-modal hide-footer hide-header id="modal-notify-sub-2" centered v-model="showNotify">
      <img src="~/mlearn/icons/modal/close-modal.svg" alt="close" class="close position-absolute pointer" @click="showNotify = false"/>
      <div class="title">Thông báo</div>
      <div class="description">{{ message }}</div>
    </b-modal>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'

const subResource = new Resource('price/sub')
export default {
  name: 'ModalRequestSub',
  data () {
    return {
      listSub: [],
      showModal: false,
      showNotify: false,
      message: ''
    }
  },
  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  },
  created () {
    this.loadSub()
  },
  methods: {
    showMessage () {
      console.log(this.listSub)
      if (this.listSub != null || this.listSub.length > 0) {
        const sub = this.listSub[0]
        if (this.isMobile) {
          // iOS detection from: http://stackoverflow.com/a/9039885/177710
          const isIOS = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
            .includes(navigator.platform) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
          if (isIOS) {
            window.open(`sms:${sub.service_number}?&body=${sub.register ? sub.unreg_syntax : sub.reg_syntax}`, '_blank')
          } else {
            window.open(`sms:${sub.service_number}?body=${sub.register ? sub.unreg_syntax : sub.reg_syntax}`, '_blank')
          }
          return
        }
        this.message = `Để xác nhận ${sub.register ? 'hủy' : 'đăng ký'} gói ${sub.unit}, mời Quý khách soạn tin ${sub.register ? sub.unreg_syntax : sub.reg_syntax} gửi ${sub.service_number} xác nhận ${sub.register ? 'hủy' : 'đăng ký'} DV. Phí DV ${this.$helper.formatMoney(sub.price, '.') + 'đồng/' + sub.unit.toLowerCase()}, ${sub.title.toLowerCase()}.`
        this.showNotify = true
        this.showModal = false
      } else {
        this.redirectSub()
      }
    },
    redirectSub () {
      this.$router.push('/goi-cuoc')
      this.$bvModal.hide('modal-request-sub')
    },
    async loadSub () {
      const {data} = await subResource.list()
      this.listSub = data
    }
  }
}
</script>

<style lang="scss">
#modal-request-sub {
  .modal-dialog {
    width: 450px;
    max-width: 100%;
    margin: auto;

    .modal-body {
      padding: 18px 20px;

      @media (max-width: 768px) {
        padding: 15px 15px;
      }

      .close-modal {
        position: absolute;
        right: 12px;
        top: 12px;
        @media (max-width: 768px) {
        }
      }

      .title {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }

        span {
          color: #F27602;
        }
      }

      .action {
        padding-top: 25px;
        @media (max-width: 768px) {
          padding-top: 20px;
        }

        button {
          text-align: center;
          color: #FFFFFF;
          border-radius: 20px;
          border: none;
          font-size: 16px;
          line-height: 19px;
          margin-right: 10px;
          padding: 10px 20px;
          min-width: 100px;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 768px) {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .detail {
          background: #82D5FF;
          font-family: 'Roboto';
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;
          @media (max-width: 768px) {
          }
        }

        .buy-now {
          background: rgba(96, 96, 96, 0.75);
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;

          color: #FFFFFF;
          @media (max-width: 768px) {
          }
        }
      }
    }
  }

  .modal-content {
    border-radius: 20px;
  }

  .logo {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
  }
}
</style>
<style lang="scss">
#modal-notify-sub-2 {
  .modal-dialog {
    max-width: 585px;
    @media (max-width: 576px) {
    }

    .modal-content {
      .modal-body {
        padding: 24px;
        text-align: center;
        @media (max-width: 1366px) {
          padding: 18px;
        }
        @media (max-width: 576px) {
          padding: 16px;
        }

        .close {
          right: 15px;
          top: 15px;
        }

        .title {
          font-weight: bold;
          font-size: 24px;
          @media (max-width: 1366px) {
            font-size: 18px;
          }
          @media (max-width: 576px) {
            font-size: 16px;
          }
        }

        .description {
          margin-top: 20px;
          font-size: 16px;
          @media (max-width: 576px) {
          }
        }
      }
    }
  }
}
</style>
