<template>
  <div class="info-container">
    <img class="question-ai" src="/ui-new/images/question-ai.png" alt="qs-image-col">
    <div class="info-header">MẸO DÙNG HIỆU QUẢ</div>
    <ul>
      <li>Ngoại ngữ: Chat GPT của Vilearn là một kênh lý tưởng hỗ trợ bạn giải đáp một các dễ hiểu các câu hỏi từ cơ bản đến nâng cao với từng thứ tiếng khác nhau, đặc biệt là tiếng anh.</li>
      <li>Ngữ Văn: các chủ đề, các bài viết hướng dẫn hay dàn ý đa dạng cho bạn thoải mái chọn lọc và tham khảo.</li>
      <li>Đề xuất các câu trả lời phù hợp nhất đối với câu hỏi rõ ràng</li>
    </ul>
    <img class="vilearn" :src="require('@/assets/mlearn/icons/menu/vilearn.svg')"/>
  </div>
</template>

<script>
export default {
  name: 'Trick'
}
</script>

<style lang="scss" scoped>
.info-container {
  width: 310px;
  padding: 60px 4px 0;
  text-align: center;
  @media(max-width: 768px) {
    width: 95%;
    min-width: 280px;
  }

  ul {
    padding: 0 8px 0 30px;
    text-align: left;

    li {
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      color: #606060;
      margin-bottom: 10px;
      text-align: left;
    }
  }

  .vilearn {
    width: auto;
    height: 22px;
    margin-top: 10px;
    margin-right: 20px;
    float: right;
  }

  .question-ai {
    display: none;
    width: 200px;
    height: 206px;
    border-radius: 120px;
    margin: 0 auto;
    @media(max-width: 768px) {
      display: block;
    }
  }
}

.info-header {
  position: relative;
  text-align: center;
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 25px;
  line-height: 60px;
  color: #606060;
  border-bottom: 2px solid #9FDBF9;
  margin-bottom: 60px;

  &::before {
    content: '';
    display: block;
    width: 46.67px;
    height: 46.67px;
    background: #fff;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -25px;
    left: -24px;
    border-bottom: 2px solid #9FDBF9;
    transform-origin: center;
  }

  @media(max-width: 768px) {
    border: none;
    margin-bottom: 20px;
    &::before {
      display: none;
    }
  }

}

</style>
