<template>
  <b-modal id="no-more-question" v-model="showModal" hide-header hide-footer centered no-close-on-backdrop no-close-on-esc>
    <img src="/icon/layout/close-modal-question.svg" alt="Close" class="pointer close-modal" @click="showModal = false"/>
    <img class="logo" :src="require('@/assets/mlearn/icons/menu/vilearn.svg')" alt="logo"/>
    <div class="title text-center">
      <!--      <p>Bạn đã dùng hết câu hỏi của ngày hôm nay.</p>-->
      <p>Quý khách đã sử dụng hết <span>10</span> câu hỏi ChatGPT ngày hôm nay! Vui lòng quay lại vào ngày mai để tiếp tục sử dụng dịch vụ.</p>
    </div>
    <!--    <div class="action d-flex justify-content-end">-->
    <!--      <button class="detail" @click="redirectSub">Đăng ký ngay</button>-->
    <!--      <button class="buy-now" @click="redirectSub">Tìm hiểu</button>-->
    <!--    </div>-->
  </b-modal>
</template>

<script>
export default {
  name: 'ModalNoMoreQuestion',
  data () {
    return {
      showModal: false
    }
  },
  methods: {
    redirectSub () {
      this.$router.push('/goi-cuoc')
      this.$bvModal.hide('no-more-question')
    }
  }
}
</script>

<style lang="scss">
#no-more-question {
  .modal-dialog {
    width: 600px;
    max-width: 100%;
    margin: auto;

    .modal-body {
      padding: 18px 20px;

      @media (max-width: 768px) {
        padding: 15px 15px;
      }

      .close-modal {
        position: absolute;
        right: 12px;
        top: 12px;
        @media (max-width: 768px) {
        }
      }

      .title {
        font-family: 'Roboto';
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        @media (max-width: 768px) {
          font-size: 16px;
          line-height: 20px;
        }

        span {
          color: #F27602;
        }
      }

      .action {
        padding-top: 25px;
        @media (max-width: 768px) {
          padding-top: 20px;
        }

        button {
          text-align: center;
          color: #FFFFFF;
          border-radius: 20px;
          border: none;
          font-size: 16px;
          line-height: 19px;
          margin-right: 10px;
          padding: 10px 20px;
          min-width: 100px;

          &:last-child {
            margin-right: 0;
          }

          @media (max-width: 768px) {
            font-size: 14px;
            line-height: 16px;
          }
        }

        .detail {
          background: #82D5FF;
          font-family: 'Roboto';
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;
          @media (max-width: 768px) {
          }
        }

        .buy-now {
          background: rgba(96, 96, 96, 0.75);
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 700;
          font-size: 13px;
          line-height: 15px;

          color: #FFFFFF;
          @media (max-width: 768px) {
          }
        }
      }
    }
  }

  .modal-content {
    border-radius: 20px;
  }

  .logo {
    width: 60px;
    height: auto;
    margin-bottom: 10px;
  }
}
</style>
